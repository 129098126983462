import TomSelect from 'tom-select/dist/js/tom-select.base.min.js';
let fields_are_initialized = false;
function change_form_label(label, input) {
  if (input.hasClass('tomselected') || input.attr('placeholder')) {
    $(label).addClass('active');
    return;
  }
  if ('' !== input.val()) {
    $(label).addClass('active');
  }

  $(label).on('click', function () {
    $(input).focus();
    if ('SELECT' === input.prop('tagName')) {
      $(input).click();
    }
  });

  $(input)
    .on('focus', function () {
      $(label).addClass('active');
    })
    .on('focusout', function () {
      var value = $.trim($(this).val());
      if (
        value.length === 0 ||
        -1 !== $(this).val().indexOf('(___) ___-____')
      ) {
        $(label).removeClass('active');
      }
    });
  if ('SELECT' === input.prop('tagName')) {
    $(input).blur(function () {
      var value = $.trim($(this).val());
      if (
        value.length === 0 ||
        -1 !== $(this).val().indexOf('(___) ___-____')
      ) {
        $(label).removeClass('active');
      }
    });
  }
}
function add_form_events() {
  $('.gfield:not(.gfield--radio)').each(function () {
    var $field = $(this);

    if ($field.hasClass('initialized')) {
      return;
    }

    var classesExcluded = [
      'ginput-wrapper--radio',
      'ginput-wrapper--checkbox',
      'ginput-wrapper--consent',
    ];

    for (var i = 0; i < classesExcluded.length; i++) {
      if ($field.hasClass(classesExcluded[i])) {
        return;
      }
    }

    var $label;
    var $input;

    $field.addClass('initialized');

    var allFields = [];

    $label = $field;
    $input = $field.find(
      '.ginput_container input , .ginput_container textarea, .ginput_container select'
    );
    if (!$input.length) {
      return;
    }
    if (1 === $input.length) {
      allFields.push([$label, $input]);
    } else {
      //it is a repeater
      $input.each(function () {
        if (!$(this).hasClass('gform_hidden')) {
          var theLabel = $(this).closest('.gfield_repeater_cell').find('label');
          if (theLabel.length) {
            allFields.push([theLabel, $(this)]);
          }
        }
      });
    }
    allFields.forEach((element) => {
      var $itemSelect = null;
      if ($field.hasClass('gfield--select')) {
        $itemSelect = element[1];
      } else if (
        $field.hasClass('gfield--repeater') &&
        'SELECT' === element[1].prop('tagName')
      ) {
        element[1].addClass('gfield_select');
        $itemSelect = element[1];
      }
      if ($itemSelect) {
        var tomselectOption = {
          create: false,
          optionClass: 'vtx_select__option',
          itemClass: 'vtx_select__item',
          controlInput: false,
          allowEmptyOption: true,
          labelField: 'Title',
        };
        var newTS = new TomSelect($itemSelect[0], tomselectOption);
        newTS.on('change', (value) => {
          if (-1 !== $itemSelect[0].id.indexOf('input_5_19')) {
            $(document).trigger('vtx_other_display_inputs', [value, newTS]);
          }
        });
      }
      change_form_label(element[0], element[1]);
    });
  });
}

function change_file_upload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').innerHTML =
        '<i class="icon icon-upload" aria-hidden="true"></i>' +
        wp.i18n._x(
          'Drag and drop file to upload or',
          'drag and drop instruction',
          'vtx'
        );

      fileUpload.querySelector('.gform_button_select_files').innerHTML =
        '<span>' +
        wp.i18n._x('Select files', 'drag and drop instruction', 'vtx') +
        '</span>';
    });
  }
}

function change_default_display_buttons_repeater($container) {
  var $allAddButtons = $container
    .children('.gfield_repeater_item')
    .children('.gfield_repeater_buttons')
    .children('.add_repeater_item');
  if ($allAddButtons.length) {
    if (1 === $allAddButtons.length) {
      $allAddButtons.css('display', 'inline-flex');
    } else {
      $allAddButtons.each(function (index) {
        if ($allAddButtons.length - 1 === index) {
          $(this).css('display', 'inline-flex');
        } else {
          $(this).css('display', 'none');
        }
      });
    }
  }
}

function initiate_any_phone_mask_in_repeater($element) {
  const all_phone_inputs_ids = [
    'input_5_7-',
    'input_5_73-',
    'input_5_16-',
    'input_5_126-',
    'input_5_25-',
    'input_5_76-',
    'input_5_33-',
    'input_5_77-',
  ];
  all_phone_inputs_ids.forEach((phone_id) => {
    if ($element.find('input[id^=' + phone_id + ']').length) {
      $element
        .find('input[id^=' + phone_id + ']')
        .mask('(999) 999-9999? x99999')
        .bind('keypress', function (e) {
          if (13 == e.which) {
            jQuery(this).blur();
          }
        });
    }
  });
}

$(document).on('gform_post_render', function () {
  //Force the wrapper to have the gravity-theme class so that our style can follow
  if (!$('.gform_wrapper').hasClass('gravity-theme')) {
    $('.gform_wrapper').addClass('gravity-theme');
  }
  fields_are_initialized = true;
  change_file_upload();
  add_form_events();
  window.gform.addFilter('gform_repeater_item_pre_add', function ($clone) {
    //Tomselect shuffle the choices of the original select for a reason we dont know
    //We force the base select to be the original so we let the order in the html to catch it later
    //the limitation of this method is that it does not generate on load the options in the ts-dropdown-content
    $clone.find('.ts-dropdown-content').each((index, selectContent) => {
      var divSelectData = $(document.createElement('div'));
      divSelectData.addClass('totransferdata');
      $(selectContent)
        .find('.vtx_select__option')
        .each((index, option) => {
          divSelectData.append(option);
        });
      $clone.append(divSelectData);
    });
    //Remove the tomselect logic from HTML duplicated by Gform repeater
    //remove all active as well, added later
    $clone.find('.ts-wrapper').detach();
    $clone.html(
      $clone
        .html()
        .replaceAll(' tomselected', '')
        .replaceAll(' ts-hidden-accessible', '')
        .replaceAll(' active', '')
    );
    //add if otherField found
    const otherField = $clone.find('input[id^="input_5_129"]');
    if (otherField) {
      if (
        !otherField
          .closest('.gfield_repeater_cell')
          .hasClass('vtx-gfield-hidden')
      ) {
        otherField
          .closest('.gfield_repeater_cell')
          .addClass('vtx-gfield-hidden');
      }
    }
    return $clone;
  });
  window.gform.addAction('gform_repeater_post_item_add', function ($clone) {
    //add tomselect right after added to the DOM
    var $newSelect = $clone.find('.gfield_select');
    if ($newSelect) {
      $newSelect.each(function () {
        const currentSelect = $(this);
        //reorder the options
        if ($clone.find('div.totransferdata').length) {
          currentSelect.html('');
          $clone
            .find('div.totransferdata')
            .first()
            .children()
            .each(function (index, elem) {
              currentSelect.append(
                '<option value="' +
                  $(elem).data('value') +
                  '">' +
                  $(elem).text() +
                  '</option>'
              );
            });
          $clone.find('div.totransferdata').first().detach();
        }
        var tomselectOption = {
          create: false,
          optionClass: 'vtx_select__option',
          itemClass: 'vtx_select__item',
          controlInput: false,
          allowEmptyOption: true,
          labelField: 'Title',
        };
        var newTS = new TomSelect(currentSelect, tomselectOption);
        newTS.on('change', (value) => {
          if (-1 !== currentSelect.attr('id').indexOf('input_5_19')) {
            $(document).trigger('vtx_other_display_inputs', [value, newTS]);
          }
        });
      });
    }
    //add the active class like others inputs
    var $inputs = $clone.find('input , textarea, select');
    $inputs.each(function () {
      if (!$(this).hasClass('gform_hidden')) {
        var theLabel = $(this).closest('.gfield_repeater_cell').find('label');
        if (theLabel.length) {
          change_form_label(theLabel, $(this));
        }
      }
    });
    //apply our function to hide the add button on all elements but the last one
    change_default_display_buttons_repeater(
      $clone.closest('.gfield_repeater_items')
    );
    //Force any phone masks inside the clone
    initiate_any_phone_mask_in_repeater($clone);
  });
  window.gform.addAction(
    'gform_repeater_post_item_delete',
    function ($container) {
      change_default_display_buttons_repeater(
        $container.children('.gfield_repeater_items')
      );
    }
  );
  //add span to save button and remove svg logo on form 5(complaint form)
  if ($('#gform_submit_button_5').length) {
    var $saveButton = $('.gform_save_link');
    $saveButton.find('svg').detach();
    var $origText = $saveButton.text().trim();
    $saveButton.text('');
    $saveButton.html('<span>' + $origText + '</span>');
    $('.gform_fields').append($('#gform_submit_button_5'));
    $('.gform_fields').append($saveButton);
  }

  //Force jquery for mobile text input on load inside repeaters on form 5(complaint form)
  if ($('#gform_fields_5').length) {
    initiate_any_phone_mask_in_repeater($('#gform_fields_5'));
  }
});

$(document).on('vtx_other_display_inputs', (e, value, TSObj) => {
  //get the last options
  let lastValue = '';
  let highestOrder = 0;
  for (const ele of Object.values(TSObj.options)) {
    if (ele.$order > highestOrder) {
      highestOrder = ele.$order;
      lastValue = ele.value;
    }
  }
  const otherField = TSObj.wrapper.closest(
    '.gfield_repeater_cell'
  ).nextElementSibling;
  if (lastValue === value) {
    //make the other field visible
    if (otherField.classList.contains('vtx-gfield-hidden')) {
      otherField.classList.remove('vtx-gfield-hidden');
    }
  } else {
    //make the other field invisible
    if (!otherField.classList.contains('vtx-gfield-hidden')) {
      otherField.classList.add('vtx-gfield-hidden');
    }
  }
});

// ON READY
window.addEventListener('load', function () {
  //Force the wrapper to have the gravity-theme class so that our style can follow
  if (!$('.gform_wrapper').hasClass('gravity-theme')) {
    $('.gform_wrapper').addClass('gravity-theme');
  }
  if (!fields_are_initialized) {
    change_file_upload();
    add_form_events();
  }
});
